import Div from "@jumbo/shared/Div/Div";
import { Button, TextField, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { ErrorMessage, Form, Formik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AllApis from "app/Apis";
import ImageUpload from "app/components/UploadImages";
import { Axios } from "index";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { wasteSqmPercentage } from "app/components/Function/wastePercentage";

export default function CreateFinishing() {
  const [isSubmitting, setSubmitting] = useState(false);
  const [finishingPcs, setFinishingPcs] = useState(0);

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const finishingData = {
    group_no: state?.group_no,
    finishing_details: [
      {
        finishing_no_of_pcs: state?.available_pressed_pcs,
        finishing_length: "",
        finishing_width: "",
        finishing_sqm: "",
        finishing_wastage_sqm: "",
        finishing_wastage_percentage: "",
      },
    ],
    finishing_remarks: "",
    finishing_images: [],
    tapping_id: state?.tapping_id,
    ready_sheet_form_inventory_id: state?.ready_sheet_form_inventory_id,
    ready_sheet_form_history_id: state?.ready_sheet_form_history_id,
    pressing_id: state?.pressing_id,
    status: state?.status,
    issued_for_finishing_id: state?._id,
  };

  const validationSchema = yup.object().shape({
    finishing_details: yup.array().of(
      yup.object().shape({
        finishing_no_of_pcs: yup.string().required("No of Pieces is required"),
        finishing_length: yup
          .number()
          .typeError("Length must be a number")
          .required("Length is required"),
        finishing_width: yup
          .number()
          .typeError("Width must be a number")
          .required("Width is required"),
        finishing_sqm: yup
          .number()
          .typeError("Sq.m. must be a number")
          .required("Sq.m. is required"),
      })
    ),
  });

  const handleSubmit = async (values) => {
    if (finishingPcs > state?.available_pressed_pcs) {
      return Swal.fire({
        position: "center",
        icon: "warning",
        text: "Finishing No of Pcs is Greater Than Available Pcs",
        customClass: {
          container: "popupImportant",
        },
        showConfirmButton: false,
        timer: 1500,
      });
    }
    // console.log(finishingPcs, "dawdawd");
    const form = new FormData();
    // Iterate over the entries of the object and append each key-value pair to the FormData object
    form.append("availablePcs", state?.available_pressed_pcs - finishingPcs);
    Object.entries(values).forEach((entry) => {
      if (entry[0] == "finishing_images") {
        entry[1].forEach((file, index) => {
          form.append(entry[0], file);
        });
      } else if (entry[0] == "finishing_details") {
        form.append(entry[0], JSON.stringify(entry[1]));
      } else {
        form.append(entry[0], entry[1]);
      }
    });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.post(
        AllApis.finishing.createFinishing,
        form,
        config
      );
      if (response?.data?.status) {
        Swal.fire({
          title: "Finishing Created",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboard/factory/finishing");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };
  // const calculateSqm = (e, setFieldValue, data) => {
  //   const { name, value } = e.target;
  //   if (name == "finishing_length") {
  //     const sqm =
  //       (value * (data.finishing_width || 0) * data?.finishing_no_of_pcs) /
  //       10000;
  //     setFieldValue("finishing_sqm", parseFloat(sqm.toFixed(2)));
  //   } else if (name == "finishing_width") {
  //     const sqm =
  //       (value * (data.finishing_length || 0) * data?.finishing_no_of_pcs) /
  //       10000;
  //     setFieldValue("finishing_sqm", parseFloat(sqm.toFixed(2)));
  //   } else {
  //     const sqm =
  //       ((data.finishing_length || 0) * (data.finishing_width || 0) * value) /
  //       10000;
  //     setFieldValue("finishing_sqm", parseFloat(sqm.toFixed(2)));
  //   }
  //   setFieldValue([name], value);
  // };

  const calculateWastage = function (index, setFieldValue, { length = 0, width = 0, pieces = 0 }) {
    const total_sqm = (state?.pressing_details?.pressing_length * state?.pressing_details?.pressing_width * pieces) / 10000;
    const final_finishing_sqm = (parseFloat(length) * parseFloat(width) * parseFloat(pieces)) / 10000;
    const finishing_wastage_sqm = parseFloat(total_sqm - final_finishing_sqm).toFixed(2);
    setFieldValue(`finishing_details.${index}.finishing_wastage_sqm`, finishing_wastage_sqm);
    setFieldValue(`finishing_details.${index}.finishing_wastage_percentage`, wasteSqmPercentage(final_finishing_sqm, finishing_wastage_sqm))
  }

  const calculateSqm = (e, setFieldValue, index, finishing_details) => {
    const { name, value } = e.target;
    const updatedDetails = [...finishing_details]; // Create a shallow copy of the array
    updatedDetails[index] = {
      ...updatedDetails[index],
      [name]: value,
    };
    // console.log(finishing_details, "updatedDetails[index]");
    // Calculate finishing_sqm based on the updated properties
    const updatedItem = updatedDetails[index];
    const { finishing_length, finishing_width, finishing_no_of_pcs } =
      updatedItem;
    const sqm =
      ((finishing_length || 0) *
        (finishing_width || 0) *
        (finishing_no_of_pcs || 0)) /
      10000;
    updatedItem.finishing_sqm = parseFloat(sqm.toFixed(2));
    //tapping No of pieces
    const total_pieces = updatedDetails.reduce((acc, ele, ind) => {
      return acc + (Number(ele?.finishing_no_of_pcs) ?? 0);
    }, 0);
    setFinishingPcs(total_pieces);
    setFieldValue("finishing_details", updatedDetails);
  };
  // console.log(finishingPcs, "finishingPcs");
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Create Finishing</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={finishingData}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, initialValues }) => (
            <Form noValidate autoComplete="off">
              {/* {console.log(errors, "errorserrors")} */}
              <Div sx={{ width: "100%" }}>
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow sx={{
                        bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                          py: 2,
                        },
                      }}>
                        <TableCell sx={{ color: "white" }}>Group No.</TableCell>
                        <TableCell sx={{ color: "white" }}>Item Name</TableCell>
                        <TableCell sx={{ color: "white" }}>Item Type</TableCell>
                        {/* <TableCell sx={{ color: "white" }}>Group Pcs</TableCell> */}
                        <TableCell sx={{ color: "white" }}>Grade</TableCell>
                        <TableCell sx={{ color: "white" }}>
                          Pallet No.
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                          Physical Location
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{state?.group_details?.group_no}</TableCell>
                        <TableCell>
                          {
                            state?.cutting_details?.cutting_id?.[0]
                              ?.item_details?.item_data?.[0]?.item_name
                          }
                        </TableCell>
                        <TableCell>
                          {
                            state?.cutting_details?.cutting_id?.[0]
                              ?.item_details?.item_data?.[0]?.item_code
                          }
                        </TableCell>
                        {/* <TableCell>{state?.group_details?.group_pcs}</TableCell> */}
                        <TableCell>
                          {state?.group_details?.group_grade}
                        </TableCell>
                        <TableCell>
                          {state?.group_details?.group_pallete_no}
                        </TableCell>
                        <TableCell>
                          {state?.group_details?.group_physical_location}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow sx={{
                        bgcolor: "#7352C7", color: "white", "& .MuiTableCell-root": {
                          py: 2,
                        },
                      }}>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Pressing Length (cm)
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Pressing Width (cm)
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Original Pressing Pcs
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Pressing Sq.m.
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Avl Prssing Pcs
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* <TableBody>
                      <TableRow>
                      {console.log(state?.pressing_details?.pressing_sqm,"state?.pressing_details?.pressing_sqm")}
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.ready_sheet_form_history_details?.group_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_length}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_width}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_no_of_peices}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_sqm}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.status}
                        </TableCell>
                      </TableRow>
                    </TableBody> */}
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_length}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_width}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_no_of_peices}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_sqm}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.available_pressed_pcs}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            px: 1,
                            textTransform: "capitalize",
                          }}
                        >
                          {state?.status}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Div>
              <Div sx={{ mt: 4, width: "100%", display: "flex", columnGap: 5 }}>
                <Div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {values?.finishing_details?.map((ele, index) => {
                    return (
                      <Div
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Div
                          sx={{
                            width: "100%",
                            display: "flex",
                            columnGap: 3,
                            // flexWrap: "wrap",
                          }}
                        >
                          <Div sx={{ width: "22.3%" }}>
                            <Typography variant="h5">
                              Finishing Length (cm)*
                            </Typography>
                            <TextField
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(
                                  e.target.value + e.key,
                                  10
                                );

                                if (isNaN(inputValue) || inputValue < 0) {
                                  e.preventDefault();
                                }
                              }}
                              sx={{ width: "100%", ...disabledStyling }}
                              size="small"
                              value={ele?.finishing_length}
                              name="finishing_length"
                              onChange={(e) => {
                                calculateSqm(
                                  e,
                                  setFieldValue,
                                  index,
                                  values?.finishing_details
                                )
                                calculateWastage(index, setFieldValue, {
                                  length: e.target.value,
                                  width: ele?.finishing_width,
                                  pieces: ele?.finishing_no_of_pcs
                                })
                              }}
                            />
                            <Div sx={{ height: "30px" }}>
                              <ErrorMessage
                                name={`finishing_details[${index}].finishing_length`}
                                component="div"
                                style={{ color: "red" }}
                              />
                            </Div>
                          </Div>
                          <Div sx={{ width: "22.3%" }}>
                            <Typography variant="h5">
                              Finishing Width (cm)*
                            </Typography>
                            <TextField
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(
                                  e.target.value + e.key,
                                  10
                                );

                                if (isNaN(inputValue) || inputValue < 0) {
                                  e.preventDefault();
                                }
                              }}
                              sx={{ width: "100%", ...disabledStyling }}
                              size="small"
                              value={ele?.finishing_width}
                              name="finishing_width"
                              onChange={(e) => {
                                calculateSqm(
                                  e,
                                  setFieldValue,
                                  index,
                                  values?.finishing_details
                                )
                                calculateWastage(index, setFieldValue, {
                                  length: ele?.finishing_length,
                                  width: e.target.value,
                                  pieces: ele?.finishing_no_of_pcs
                                })
                              }}
                            />
                            <Div sx={{ height: "30px" }}>
                              <ErrorMessage
                                name={`finishing_details[${index}].finishing_width`}
                                component="div"
                                style={{ color: "red" }}
                              />
                            </Div>
                          </Div>
                          <Div sx={{ width: "22.3%" }}>
                            <Typography variant="h5">No. of Pieces*</Typography>
                            <TextField
                              type="number"
                              sx={{ width: "100%", ...disabledStyling }}
                              size="small"
                              // disabled
                              inputProps={{
                                min: 1,
                                max: state?.available_pressed_pcs,
                              }}
                              onKeyDown={(e) => {
                                const inputValue = parseInt(
                                  e.target.value + e.key,
                                  10
                                );

                                if (
                                  isNaN(inputValue) ||
                                  inputValue < 0 ||
                                  inputValue > state?.available_pressed_pcs
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              name="finishing_no_of_pcs"
                              value={ele?.finishing_no_of_pcs}
                              onChange={(e) => {
                                calculateSqm(
                                  e,
                                  setFieldValue,
                                  index,
                                  values?.finishing_details
                                )
                                calculateWastage(index, setFieldValue, {
                                  length: ele?.finishing_length,
                                  width: ele?.finishing_width,
                                  pieces: e.target.value,
                                })
                              }}
                            />
                            <Div sx={{ height: "30px" }}>
                              <ErrorMessage
                                name={`finishing_details[${index}].finishing_no_of_pcs`}
                                component="div"
                                style={{ color: "red" }}
                              />
                            </Div>
                          </Div>

                          <Div sx={{ width: "22.3%" }}>
                            <Typography variant="h5">Finishing Sq.m.*</Typography>
                            <TextField
                              type="number"
                              sx={{ width: "100%", ...disabledStyling }}
                              disabled
                              size="small"
                              value={ele?.finishing_sqm}
                              name="finishing_sqm"
                            />
                            <Div sx={{ height: "30px" }}>
                              <ErrorMessage
                                name={`finishing_details[${index}].finishing_sqm`}
                                component="div"
                                style={{ color: "red" }}
                              />
                            </Div>
                          </Div>
                          <Div sx={{ width: "22.3%" }}>
                            <Typography variant="h5">Wastage Sq.m.*</Typography>
                            <TextField
                              type="number"
                              sx={{ width: "100%", ...disabledStyling }}
                              disabled
                              size="small"
                              value={parseFloat(ele?.finishing_wastage_sqm) === 0 ? 0 : ele?.finishing_wastage_sqm}
                              name="finishing_wastage_sqm"
                            />
                          </Div>
                          <Div sx={{ width: "22.3%" }}>
                            <Typography variant="h5">Wastage Percentage*</Typography>
                            <TextField
                              type="number"
                              sx={{ width: "100%", ...disabledStyling }}
                              disabled
                              size="small"
                              value={ele?.finishing_wastage_percentage}
                              name="finishing_wastage_percentage"
                            />
                          </Div>
                          <Div sx={{ width: "10%", mt: index == 0 ? 4 : 4 }}>
                            {values?.finishing_details?.length - 1 == index && (
                              <>
                                {index != 0 && (
                                  <RemoveCircleOutlineIcon
                                    sx={{
                                      cursor: "pointer",
                                      mr: 1,
                                      color: "red",
                                    }}
                                    onClick={() => {
                                      const newData =
                                        values.finishing_details.filter(
                                          (_, i) => i !== index
                                        );

                                      const total_pieces = newData.reduce(
                                        (acc, ele, ind) => {
                                          return (
                                            acc +
                                            (Number(ele?.finishing_no_of_pcs) ??
                                              0)
                                          );
                                        },
                                        0
                                      );
                                      setFinishingPcs(total_pieces);
                                      setFieldValue(
                                        "finishing_details",
                                        newData
                                      );
                                    }}
                                  />
                                )}

                                <AddCircleOutlineIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setFieldValue("finishing_details", [
                                      ...values.finishing_details,
                                      {
                                        ...initialValues.finishing_details[0],
                                        finishing_no_of_pcs: "",
                                      },
                                    ]);
                                  }}
                                />
                              </>
                            )}
                            {values?.finishing_details?.length - 1 !==
                              index && (
                                <RemoveCircleOutlineIcon
                                  sx={{ cursor: "pointer", color: "red" }}
                                  onClick={() => {
                                    const newData =
                                      values.finishing_details.filter(
                                        (_, i) => i !== index
                                      );
                                    const total_pieces = newData.reduce(
                                      (acc, ele, ind) => {
                                        return (
                                          acc +
                                          (Number(ele?.finishing_no_of_pcs) ?? 0)
                                        );
                                      },
                                      0
                                    );
                                    setFinishingPcs(total_pieces);
                                    setFieldValue("finishing_details", newData);
                                  }}
                                />
                              )}
                          </Div>
                        </Div>
                      </Div>
                    );
                  })}
                  <Div sx={{ width: "100%" }}>
                    <Div sx={{ width: "50%" }}>
                      <ImageUpload
                        setFieldValue={setFieldValue}
                        values={values}
                        name={"finishing_images"}
                      />
                      <Div sx={{ height: "30px", mt: -2, textAlign: "center" }}>
                        <ErrorMessage
                          name="finishing_images"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                  </Div>
                  <Div sx={{ width: "50%" }}>
                    <Typography variant="h5">Finishing Remarks</Typography>
                    <TextField
                      // disabled
                      sx={{ width: "100%" }}
                      size="small"
                      value={values?.finishing_remarks}
                      name="finishing_remarks"
                      onChange={(e) => {
                        setFieldValue("finishing_remarks", e.target.value);
                      }}
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="finishing_remarks"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                </Div>
              </Div>

              <Div sx={{ mt: 4, width: "100%" }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    columnGap: 3,
                    flexWrap: "wrap",
                  }}
                ></Div>
              </Div>

              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/dashboard/factory/finishing");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Create Finishing
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
