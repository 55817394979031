import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AllApis from "app/Apis";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { wasteSqmPercentage } from "app/components/Function/wastePercentage";
import { getIssueForPressingList } from "app/redux/actions/factoryAction";
import { ErrorMessage, Form, Formik } from "formik";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";

const CreatePressingModal = ({ open, onClose, rowData }) => {
  const [pressing, setPressing] = useState({}); // Separate state for pressing quantity
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [itemNameList, setItemNameList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const { _id, ...rest } = rowData;
    setPressing({ ...rest });
  }, [rowData]);

  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    (async () => {
      const itemNameList = await Axios.get(`${AllApis.dropdownList.item_name("plywood")}`);
      setItemNameList(itemNameList?.data?.result);
    })();
  }, []);

  const validationSchema = yup.object({
    pressing_no_of_peices: yup.number().positive("Enter Valid Number").required("Required"),
    pressing_length: yup.number().positive("Enter Valid Number").required("Required"),
    pressing_width: yup.number().positive("Enter Valid Number").required("Required"),
    pressing_sqm: yup.number().positive("Enter Valid Number").required("Required"),
    consumed_quantity: yup.number().positive("Enter Valid Number").required("Required"),
    consumed_item_name: yup.string().required("Consumed Item is required"),
  });

  const calculateSqm = (e, setFieldValue, data) => {
    const { name, value } = e.target;
    if (name == "pressing_length") {
      const sqm = (value * (data.pressing_width || 0) * data?.pressing_no_of_peices) / 10000;
      setFieldValue("pressing_sqm", parseFloat(sqm.toFixed(2)));
    } else if (name == "pressing_width") {
      const sqm = (value * (data.pressing_length || 0) * data?.pressing_no_of_peices) / 10000;
      setFieldValue("pressing_sqm", parseFloat(sqm.toFixed(2)));
    } else if (name == "pressing_no_of_peices") {
      const sqm = (((data.pressing_length || 0) * data.pressing_width || 0) * value) / 10000;
      setFieldValue("pressing_sqm", parseFloat(sqm.toFixed(2)));
      setFieldValue("available_pressed_pcs", value);
      setFieldValue("remaining_no_of_peices", pressing?.ready_sheet_form_history_details?.ready_sheet_form_approved_pcs - value);
    }
    setFieldValue([name], value);
  };

  const handleOnSave = async (values) => {
    const pressingWasteSqm = {
      waste_sqm: 0,
      waste_sqm_percentage: 0,
    };
    const readySheetFormLength = rowData?.ready_sheet_form_history_details?.ready_sheet_form_length;
    const readySheetFormWidth = rowData?.ready_sheet_form_history_details?.ready_sheet_form_width;
    const totalReadySheetFormSqm = parseFloat((readySheetFormLength * readySheetFormWidth * values?.pressing_no_of_peices) / 10000).toFixed(
      2
    );

    const wasteSqm = parseFloat(totalReadySheetFormSqm - values?.pressing_sqm).toFixed(2);
    const wastePercentage = wasteSqmPercentage(values?.pressing_sqm, wasteSqm);

    pressingWasteSqm.waste_sqm = wasteSqm;
    pressingWasteSqm.waste_sqm_percentage = wastePercentage;

    const body = {
      ...pressing,
      ...values,
      pressing_waste_sqm: pressingWasteSqm,
    };

    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setIsSubmitting(true);
      const response = await Axios.post(AllApis.pressing.createPressing, body, config);
      onClose(false);
      dispatch(getIssueForPressingList("", "desc", "updated_at", 1, {}));
      if (response?.data?.status) {
        Swal.fire({
          customClass: {
            container: "popupImportant",
          },
          title: "Pressing Created Successfully",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        customClass: {
          container: "popupImportant",
        },
        title: error?.response?.data.message,
        icon: "error",
        // timer: 1000,
        showConfirmButton: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <Formik
        validateOnChange={true}
        validationSchema={validationSchema}
        initialValues={{
          group_no: pressing?.group_no,
          tapping_id: pressing?.ready_sheet_form_history_details?.tapping_id,
          ready_sheet_form_history_id: pressing?.ready_sheet_form_history_details?._id,
          ready_sheet_form_inventory_id: pressing?.ready_sheet_form_inventory_id,
          pressing_no_of_peices: pressing?.ready_sheet_form_history_details?.ready_sheet_form_approved_pcs,
          remaining_no_of_peices: 0,
          available_pressed_pcs: pressing?.ready_sheet_form_history_details?.ready_sheet_form_approved_pcs,
          pressing_length: pressing?.ready_sheet_form_history_details?.ready_sheet_form_length,
          pressing_width: pressing?.ready_sheet_form_history_details?.ready_sheet_form_width,
          pressing_sqm: pressing?.ready_sheet_form_history_details?.ready_sheet_form_sqm,
          pressing_remarks: "",
          consumed_remarks: "",
          consumed_item_name: "",
          consumed_quantity: 0,
          date_of_consumption: getCurrentDate(),
          issued_for_pressing_id: rowData?._id,
        }}
        enableReinitialize={true}
        onSubmit={(values) => handleOnSave(values)}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <DialogContent>
            <Div sx={{ minWidth: 600 }}>
              <DialogTitle sx={{ fontWeight: "700", marginLeft: "-22px" }}>
                <Typography variant="h3" color="initial" fontWeight={600}>
                  Create Pressing
                </Typography>
              </DialogTitle>
            </Div>
            <Div>
              <Form>
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow
                        sx={{
                          bgcolor: "#7352C7",
                          color: "white",
                          "& .MuiTableCell-root": {
                            py: 2,
                          },
                        }}
                      >
                        <TableCell sx={{ color: "white" }}>Group No.</TableCell>
                        <TableCell sx={{ color: "white" }}>Item Name</TableCell>
                        <TableCell sx={{ color: "white" }}>Item Type</TableCell>
                        {/* <TableCell sx={{ color: "white" }}>Group Pcs</TableCell> */}
                        <TableCell sx={{ color: "white" }}>Grade</TableCell>
                        <TableCell sx={{ color: "white" }}>Pallet No.</TableCell>
                        <TableCell sx={{ color: "white" }}>Physical Location</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{pressing?.group_details?.group_no}</TableCell>
                        <TableCell>{pressing?.cutting_details?.cutting_id?.[0]?.item_details?.item_data?.[0]?.item_name}</TableCell>
                        <TableCell>{pressing?.cutting_details?.cutting_id?.[0]?.item_details?.item_data?.[0]?.item_code}</TableCell>
                        {/* <TableCell>
                          {pressing?.group_details?.group_pcs}
                        </TableCell> */}
                        <TableCell>{pressing?.group_details?.group_grade}</TableCell>
                        <TableCell>{pressing?.group_details?.group_pallete_no}</TableCell>
                        <TableCell>{pressing?.group_details?.group_physical_location}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Div
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    columnGap: 3,
                    width: "100%",
                  }}
                >
                  <Div sx={{ width: "23%" }}>
                    <Typography variant="h5">Pressing No Of Pieces*</Typography>
                    <TextField
                      size="small"
                      name="pressing_no_of_peices"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 0,
                        max: pressing?.ready_sheet_form_history_details?.ready_sheet_form_approved_pcs,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > pressing?.ready_sheet_form_history_details?.ready_sheet_form_approved_pcs
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => calculateSqm(e, setFieldValue, values)}
                      value={values?.pressing_no_of_peices}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name="pressing_no_of_peices" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>

                  <Div sx={{ width: "23%" }}>
                    <Typography variant="h5">Pressing Length (cm)*</Typography>
                    <TextField
                      // disabled
                      sx={{ width: "100%" }}
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (isNaN(inputValue) || inputValue < 0) {
                          e.preventDefault();
                        }
                      }}
                      value={values?.pressing_length}
                      name="pressing_length"
                      onChange={(e) => calculateSqm(e, setFieldValue, values)}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name="pressing_length" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>
                  <Div sx={{ width: "23%" }}>
                    <Typography variant="h5">Pressing Width (cm)*</Typography>
                    <TextField
                      // disabled
                      sx={{ width: "100%" }}
                      size="small"
                      type="number"
                      value={values?.pressing_width}
                      inputProps={{
                        min: 0,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (isNaN(inputValue) || inputValue < 0) {
                          e.preventDefault();
                        }
                      }}
                      name="pressing_width"
                      onChange={(e) => calculateSqm(e, setFieldValue, values)}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name="pressing_width" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>

                  <Div sx={{ width: "23%" }}>
                    <Typography variant="h5">Pressing Sq.m.*</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      disabled
                      size="small"
                      type="number"
                      value={values?.pressing_sqm}
                      name="pressing_sqm"
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name="pressing_sqm" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>

                  <Div sx={{ width: "23%" }}>
                    <Typography variant="h5">Consumed Item*</Typography>
                    <Autocomplete
                      size="small"
                      id="tags-standard"
                      options={itemNameList || []}
                      sx={{ width: "100%" }}
                      name="consumed_item_name"
                      getOptionLabel={(option) => option?.item_name}
                      onChange={(e, newValue) => {
                        setFieldValue("consumed_item_name", newValue?.item_name);
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          {option?.item_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name="consumed_item_name" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>
                  <Div sx={{ width: "23%" }}>
                    <Typography variant="h5">Consumed Quantity</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      type="number"
                      value={values?.consumed_quantity}
                      name="consumed_quantity"
                      inputProps={{
                        min: 0,
                      }}
                      onChange={(e) => calculateSqm(e, setFieldValue, values)}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name="consumed_quantity" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>
                  <Div sx={{ width: "23%" }}>
                    <Typography variant="h5">Consumed Remarks</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      value={values?.consumed_remarks}
                      name="consumed_remarks"
                      onChange={(e) => calculateSqm(e, setFieldValue, values)}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name="consumed_remarks" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>
                  <Div sx={{ width: "23%" }}>
                    <Typography variant="h5">Remarks</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      value={values?.pressing_remarks}
                      name="pressing_remarks"
                      onChange={(e) => calculateSqm(e, setFieldValue, values)}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage name="pressing_remarks" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>
                </Div>
                <Div
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LoadingButton type="submit" variant="contained" sx={{ mt: 4 }} loading={isSubmitting}>
                    Create
                  </LoadingButton>
                </Div>
              </Form>
            </Div>
          </DialogContent>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreatePressingModal;
