import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllApis from "app/Apis";
import FilterAccordian from "app/components/FilterAccordian";
import { handleLogs } from "app/components/Function/logsDownloadFunction";
import FullScreenLoader from "app/components/ListingPageLoader/index";
import {
  getAllIssuedDying,
  getAllIssuedGrouping,
  getAllIssuedSmoking,
  getAllRawMaterial,
  getAllRawMaterialHistory,
} from "app/redux/actions/inventoryAction";
import { supplierList } from "app/services/apis/supplierList";
import dayjs from "dayjs";
import { Axios } from "index";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import IssuedDyingTable from "../TableComponent/IssuedForDyingTable";
import IssuedGroupTable from "../TableComponent/IssuedForGroupingTable";
import IssuedSmokeTable from "../TableComponent/IssuedForSmokingTable";
import RawMaterialHistoryTable from "../TableComponent/RawMaterialHistory";
import RawMaterialTable from "../TableComponent/RawMaterialTable";

const InventoryList = () => {
  const [loaded, setLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = React.useState(0);
  const [totalSqm, setTotalSqm] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("updated_at");
  const [itemNameList, setItemNameList] = useState([]);
  const [itemCodeList, setItemCodeList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [palleteList, setPalleteList] = useState([]);
  const [emptyVal, setEmptyVal] = useState();
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [addGroup, setAddGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pgNo, setPgNo] = useState("");
  const [filters, setFilters] = useState({
    from: "",
    to: "",
  });
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );
  const roleName = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.role_name
  );
  const state = useSelector((state) => state?.inventoryReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const TabChange = () => {
    setFilters({
      from: "",
      to: "",
    });
    setEmptyVal(!emptyVal);
    setPage(1);
    setSort("desc");
    setSortBy("updated_at");
    setSearchTerm("");
  };
  const filterFun = (type) => {
    const filter = {};
    for (let i in filters) {
      if (filters[i] != "" && i != "from" && i != "to") {
        if (type == "item_id") {
          filter[`${"item_id"}.${i}`] = filters[i];
        } else {
          filter[`${i}`] = filters[i];
        }
      }
      if ((i == "from" && i["from"] != "") || (i == "to" && i["to"] != "")) {
        filter[i] = filters[i];
      }
    }
    return filter;
  };

  const handleSearch = (search) => {
    setPage(1);
    if (value == 0) {
      setAddGroup([]);
      dispatch(getAllRawMaterial(search, sort, sortBy, page, filterFun("")));
    } else if (value == 1) {
      dispatch(
        getAllIssuedSmoking(search, sort, sortBy, page, filterFun("item_id"))
      );
    } else if (value == 2) {
      dispatch(
        getAllIssuedDying(search, sort, sortBy, page, filterFun("item_id"))
      );
    } else if (value == 3) {
      dispatch(
        getAllIssuedGrouping(search, sort, sortBy, page, filterFun("item_id"))
      );
    } else if (value == 4) {
      dispatch(
        getAllRawMaterialHistory(search, sort, sortBy, page, filterFun(""))
      );
    }
    setLoaded(true);
  };

  const debouncedHandleSearch = debounce(handleSearch, 1000);

  // useEffect(() => {
  //   if (searchTerm !== "") {
  //     debouncedHandleSearch(searchTerm);
  //   }
  //   return () => {
  //     debouncedHandleSearch.cancel();
  //   };
  // }, [searchTerm]);

  useEffect(() => {
    //to deal with search lagging
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    } else {
      setLoaded(true);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  const handleFilter = () => {
    setPage(1);
    if (value == 0) {
      setAddGroup([]);
      dispatch(getAllRawMaterial(searchTerm, sort, sortBy, "", filterFun("")));
    } else if (value == 1) {
      dispatch(
        getAllIssuedSmoking(searchTerm, sort, sortBy, "", filterFun("item_id"))
      );
    } else if (value == 2) {
      dispatch(
        getAllIssuedDying(searchTerm, sort, sortBy, "", filterFun("item_id"))
      );
    } else if (value == 3) {
      dispatch(
        getAllIssuedGrouping(searchTerm, sort, sortBy, "", filterFun("item_id"))
      );
    } else if (value == 4) {
      dispatch(
        getAllRawMaterialHistory(searchTerm, sort, sortBy, "", filterFun(""))
      );
    }
  };
  const handleClear = () => {
    TabChange();
    if (value == 0) {
      setAddGroup([]);
      dispatch(getAllRawMaterial(searchTerm, sort, sortBy, page, {}));
    } else if (value == 1) {
      dispatch(getAllIssuedSmoking(searchTerm, sort, sortBy, page, {}));
    } else if (value == 2) {
      dispatch(getAllIssuedDying(searchTerm, sort, sortBy, page, {}));
    } else if (value == 3) {
      dispatch(getAllIssuedGrouping(searchTerm, sort, sortBy, page, {}));
    } else if (value == 4) {
      dispatch(getAllRawMaterialHistory(searchTerm, sort, sortBy, page, {}));
    }
  };

  const datepickerStyling = {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aeb7bd ", // or any other color you prefer
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#475259", // Change to the color you want on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7352C7", // Change to the color you want when focused
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#7352C7", // Keep the same color as focused when hovered
    },
    "& .MuiInputLabel-outlined": {
      color: "#98a3aa", // Default label color
      "&.Mui-focused": {
        color: "#7352C7", // Label color on focus
      },
    },
  };
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  // navs and tab functionality
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    TabChange();
    if (newValue == 0) {
      setAddGroup([]);
      dispatch(getAllRawMaterial("", sort, sortBy, page, {}));
    } else if (newValue == 1) {
      dispatch(getAllIssuedSmoking("", sort, sortBy, page, {}));
    } else if (newValue == 2) {
      dispatch(getAllIssuedDying("", sort, sortBy, page, {}));
    } else if (newValue == 3) {
      dispatch(getAllIssuedGrouping("", sort, sortBy, page, {}));
    } else if (newValue == 4) {
      dispatch(getAllRawMaterialHistory("", sort, sortBy, page, {}));
    }
  };

  const getSqm = async () => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    try {
      const response = await Axios.get(AllApis.raw_material.listSqm, config);
      setTotalSqm(response.data.totalSqm);
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
    }
  };

  useEffect(async () => {
    try {
      const data = await supplierList();
      if (data.data.status == true) {
        setSupplierNameList(data.data.result);
      }
      const itemNameList = await Axios.get(`${AllApis.dropdownList.item_name()}`);
      setItemNameList(itemNameList?.data?.result);

      const itemCodeList = await Axios.get(`${AllApis.dropdownList.item_code}`);
      setItemCodeList(itemCodeList?.data?.result);

      const gradeList = await Axios.get(`${AllApis.dropdownList.grade}`);
      setGradeList(gradeList?.data?.result);

      const palleteList = await Axios.get(`${AllApis.dropdownList.pallete}`);
      setPalleteList(palleteList?.data?.result);

      getSqm();
    } catch (error) { }
  }, []);

  //export csv
  const exportRawCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.rawVeneer,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const exportIssuedSmokeCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
        status: "issued for smoking",
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.issueForSmoking,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const exportIssuedDyingCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
        status: "issued for dying",
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.issueForDying,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const exportIssuedGroupingCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.issueForGrouping,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const exportDashboardCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.dashboard(filters.from, filters.to),
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const importRawMaterial = async (file) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
        "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
      },
    };
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("excelFile", file); // Append your Excel file to the FormData

      const response = await Axios.post(AllApis.bulk.raw, formData, config);
      if (response?.data?.status === true) {
        dispatch(
          getAllRawMaterial(searchTerm, sort, sortBy, page, filterFun(""))
        );
        Swal.fire({
          title: "Uploaded",
          icon: "success",
          timer: 5000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        timer: 5000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    importRawMaterial(file);

    // Reset the file input value to allow multiple uploads
    e.target.value = null;
  };

  useEffect(() => {
    if (value == 0) {
      dispatch(
        getAllRawMaterial(searchTerm, sort, sortBy, page, filterFun(""))
      );
    } else if (value == 1) {
      dispatch(
        getAllIssuedSmoking(
          searchTerm,
          sort,
          sortBy,
          page,
          filterFun("item_id")
        )
      );
    } else if (value == 2) {
      dispatch(
        getAllIssuedDying(searchTerm, sort, sortBy, page, filterFun("item_id"))
      );
    } else if (value == 3) {
      dispatch(
        getAllIssuedGrouping(
          searchTerm,
          sort,
          sortBy,
          page,
          filterFun("item_id")
        )
      );
    } else if (value == 4) {
      dispatch(
        getAllRawMaterialHistory(searchTerm, sort, sortBy, page, filterFun(""))
      );
    }
  }, [sort, page]);

  const handleInputChange = debounce((value) => {
    if (value == "") {
      dispatch(getAllRawMaterial(searchTerm, sort, sortBy, 1, filterFun("")));
      setPage(1);
    } else {
      dispatch(
        getAllRawMaterial(searchTerm, sort, sortBy, value, filterFun(""))
      );
      setPage(Number(value));
    }
    setLoaded(true);
  }, 1000);

  useEffect(() => {
    if (pgNo !== "") {
      handleInputChange(pgNo);
    } else {
      handleInputChange(1);
      setLoaded(true);
    }
    return () => {
      handleInputChange.cancel();
    };
  }, [pgNo]);

  useEffect(() => {
    //to deal with search input lagging
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    // Clean-up function to clear the timer in case the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Typography variant="h1" sx={{ mt: -4 }}>
        Raw Veneer
      </Typography>
      <FilterAccordian
        actions={
          <Div>
            <Button
              variant="contained"
              sx={{ marginRight: 1 }}
              onClick={handleFilter}
            >
              Apply
            </Button>
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
          </Div>
        }
      >
        <Box
          sx={{ display: "flex", rowGap: 2, flexWrap: "wrap", gap:"10px" }}
        >
          <Div sx={{ width: "24%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={supplierNameList || []}
              getOptionLabel={(option) => option?.supplier_name || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "supplier_details.supplier_name":
                    newValue != null ? newValue?.supplier_name : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.supplier_name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Supplier Name"
                />
              )}
            />
          </Div>
          <Div sx={{ width: "24%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={itemNameList || []}
              getOptionLabel={(option) => option?.item_name || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  item_name: newValue != null ? newValue?.item_name : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.item_name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Item Name" />
              )}
            />
          </Div>
          <Div sx={{ width: "24%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={itemCodeList || []}
              getOptionLabel={(option) => option?.item_code || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  item_code: newValue != null ? newValue?.item_code : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.item_code}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Item Type" />
              )}
            />
          </Div>
          <Div sx={{ width: "24%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={gradeList || []}
              getOptionLabel={(option) => option?.grade_name || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  item_grade: newValue != null ? newValue?.grade_name : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.grade_name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Grade" />
              )}
            />
          </Div>
          <Div sx={{ width: "24%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={palleteList || []}
              getOptionLabel={(option) => option?.pallete_no || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  item_pallete_no: newValue != null ? newValue?.pallete_no : "",
                  item_physical_location:
                    newValue != null ? newValue?.item_physical_location : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.pallete_no}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Pallet No" />
              )}
            />
          </Div>
          {/* <FormControl sx={{ width: "24%" }} size="small">
            {(filters?.item_physical_location == "" ||
              filters?.item_physical_location == undefined) && (
                <InputLabel id="demo-select-small-label">
                  Physical Location
                </InputLabel>
              )}
            <TextField
              multiline
              size="small"
              label={
                (filters?.item_physical_location != "" ||
                  filters?.item_physical_location != undefined) &&
                "Physical Location"
              }
              value={filters?.item_physical_location || ""}
              sx={{ width: "100%", ...disabledStyling }}
              name="item_physical_location"
              disabled
              inputProps={{ shrink: true }}
              InputLabelProps={{
                style: { color: "#98A3AA" }, // Change the color of the label here
              }}
            />
          </FormControl> */}
          <Div sx={{ width: "24%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="from"
                format="DD-MM-YYYY"
                name="from"
                label={
                  value == 1 || value == 2 || value == 3
                    ? "From Issued Date"
                    : "From Inward Date"
                }
                sx={datepickerStyling}
                defaultValue={filters.from !== "" ? dayjs(filters.from) : null}
                maxDate={dayjs()}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    from: newValue
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {filters?.to && !filters?.from && (
              <Typography
                sx={{
                  color: "#E73145",
                  fontSize: "0.75rem",
                  ml: 1,
                  mt: 0.5,
                }}
              >
                {value == 1 || value == 2 || value == 3
                  ? "Select From Issued Date"
                  : "Select From Inward Date"}
              </Typography>
            )}
          </Div>
          <Div sx={{ width: "24%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="to"
                format="DD-MM-YYYY"
                name="to"
                label={
                  value == 1 || value == 2 || value == 3
                    ? "To Issued Date"
                    : "To Inward Date"
                }
                sx={datepickerStyling}
                defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
                maxDate={dayjs()}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    to: newValue
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {!filters?.to && filters?.from && (
              <Typography
                sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
              >
                {value == 1 || value == 2 || value == 3
                  ? "Select To Issued Date"
                  : "Select To Inward Date"}
              </Typography>
            )}
          </Div>
        </Box>
      </FilterAccordian>
      {/* <Div className="Filters" sx={{ width: "100%", mt: 4 }}>
        <Typography variant="h1" sx={{ mb: 3 }}>
          Filters
        </Typography>
        <Box
          sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
        >
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={supplierNameList || []}
              getOptionLabel={(option) => option?.supplier_name || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "supplier_details.supplier_name":
                    newValue != null ? newValue?.supplier_name : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.supplier_name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Supplier Name"
                />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={itemNameList || []}
              getOptionLabel={(option) => option?.item_name || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  item_name: newValue != null ? newValue?.item_name : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.item_name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Item Name" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={itemCodeList || []}
              getOptionLabel={(option) => option?.item_code || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  item_code: newValue != null ? newValue?.item_code : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.item_code}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Item Type" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={gradeList || []}
              getOptionLabel={(option) => option?.grade_name || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  item_grade: newValue != null ? newValue?.grade_name : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.grade_name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Grade" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={palleteList || []}
              getOptionLabel={(option) => option?.pallete_no || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  item_pallete_no: newValue != null ? newValue?.pallete_no : "",
                  item_physical_location:
                    newValue != null ? newValue?.item_physical_location : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.pallete_no}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Pallet No" />
              )}
            />
          </Div>
          <FormControl sx={{ width: "23%" }} size="small">
            {(filters?.item_physical_location == "" ||
              filters?.item_physical_location == undefined) && (
                <InputLabel id="demo-select-small-label">
                  Physical Location
                </InputLabel>
              )}
            <TextField
              multiline
              size="small"
              label={
                (filters?.item_physical_location != "" ||
                  filters?.item_physical_location != undefined) &&
                "Physical Location"
              }
              value={filters?.item_physical_location || ""}
              sx={{ width: "100%", ...disabledStyling }}
              name="item_physical_location"
              disabled
              inputProps={{ shrink: true }}
              InputLabelProps={{
                style: { color: "#98A3AA" }, // Change the color of the label here
              }}
            />
          </FormControl>

          <Div sx={{ width: "23%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="from"
                format="DD-MM-YYYY"
                name="from"
                label={
                  value == 1 || value == 2 || value == 3
                    ? "From Issued Date"
                    : "From Inward Date"
                }
                sx={datepickerStyling}
                defaultValue={filters.from !== "" ? dayjs(filters.from) : null}
                maxDate={dayjs()}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    from: newValue
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {filters?.to && !filters?.from && (
              <Typography
                sx={{
                  color: "#E73145",
                  fontSize: "0.75rem",
                  ml: 1,
                  mt: 0.5,
                }}
              >
                {value == 1 || value == 2 || value == 3
                  ? "Select From Issued Date"
                  : "Select From Inward Date"}
              </Typography>
            )}
          </Div>
          <Div sx={{ width: "23%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="to"
                format="DD-MM-YYYY"
                name="to"
                label={
                  value == 1 || value == 2 || value == 3
                    ? "To Issued Date"
                    : "To Inward Date"
                }
                sx={datepickerStyling}
                defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
maxDate={dayjs()}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    to: newValue
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {!filters?.to && filters?.from && (
              <Typography
                sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
              >
                {value == 1 || value == 2 || value == 3
                  ? "Select To Issued Date"
                  : "Select To Inward Date"}
              </Typography>
            )}
          </Div>
        </Box>
        <Box
          sx={{
            mt: 4,
            mb: 6,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Div>
            <Button
              variant="contained"
              sx={{ marginRight: 1 }}
              onClick={handleFilter}
            >
              Apply
            </Button>
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
          </Div>

        </Box>
      </Div> */}
      <Div sx={{ display: "flex", columnGap: 1, justifyContent: "flex-end", my: 3 }}>
        {permissions?.inventory_create && value === 0 && (
          <Button
            size="small"
            variant="contained"
            onClick={() => navigate("/dashboard/inventory/add")}
          >
            Add Stock
          </Button>
        )}

        {value !== 4 && permissions?.inventory_view && (
          <Button
            size="small"
            sx={{ ml: 1 }}
            disabled={
              (value === 0 && state?.rawMaterial?.length === 0) ||
              (value === 1 && state?.issuedSmoking?.length === 0) ||
              (value === 2 && state?.issuedDying?.length === 0) ||
              (value === 3 && state?.issuedGrouping?.length === 0)
            }
            variant="contained"
            onClick={async () => {
              if (value === 0) exportRawCsv(filterFun(""));
              if (value === 1) exportIssuedSmokeCsv(filterFun("item_id"));
              if (value === 2) exportIssuedDyingCsv(filterFun("item_id"));
              if (value === 3)
                exportIssuedGroupingCsv(filterFun("item_id"));
            }}
          >
            Export CSV
          </Button>
        )}
        {permissions?.inventory_view == true && (
          <LoadingButton
            size="small"
            variant="contained"
            sx={{
              // p: 1,
              // pl: 4,
              // pr: 4,
              ml: 1,
            }}
            onClick={() => {
              const logs = [
                {
                  url: "raw-material-inventory/rawmaterial-logs",
                  file: "Raw Materials",
                  value: 0,
                },
                {
                  url: "raw-material-inventory/issuedForSmoking-logs",
                  file: "Issued For Smoking",
                  value: 1,
                },
                {
                  url: "raw-material-inventory/issuedForDying-logs",
                  file: "Issued For Dying",
                  value: 2,
                },
                {
                  url: "raw-material-inventory/issuedForGrouping-logs",
                  file: "Issued For Grouping",
                  value: 3,
                },
                {
                  url: "raw-material-inventory/rawmaterialhistory-logs",
                  file: "Raw Veneer History",
                  value: 4,
                },
              ].filter((ele) => ele.value == value);
              handleLogs(logs[0].url, logs[0].file);
            }}
          >
            Log
          </LoadingButton>
        )}
        {roleName == "Super Admin" && (
          <Button
            size="small"
            sx={{ ml: 1 }}
            variant="contained"
            disabled={filters.from && filters.to ? false : true}
            onClick={async () => {
              exportDashboardCsv({});
            }}
          >
            Export Dashboard CSV
          </Button>
        )}
        {permissions?.inventory_create && value === 0 && (
          <Div>
            <form>
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="fileInput"
              />
              <label htmlFor="fileInput">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component="span"
                  sx={{height:"100%"}}
                >
                  Import
                </Button>
              </label>
            </form>
          </Div>
        )}
      </Div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Raw Veneer" {...a11yProps(0)} />
            <Tab label="Issued For Smoking" {...a11yProps(1)} />
            <Tab label="Issued For Dying" {...a11yProps(2)} />
            <Tab label="Issued For Grouping" {...a11yProps(3)} />
            <Tab label="Raw Veneer History" {...a11yProps(4)} />
          </Tabs>
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            onChange={(e) => {
              setLoaded(false);
              setSearchTerm(e.target.value);
              if (e.target.value == "") {
                setSort("desc");
                setSortBy("updated_at");
                if (value == 0) {
                  dispatch(
                    getAllRawMaterial("", "desc", "updated_at", 1, filters)
                  );
                  setAddGroup([]);
                } else if (value == 1) {
                  dispatch(
                    getAllIssuedSmoking(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filterFun("item_id")
                    )
                  );
                } else if (value == 2) {
                  dispatch(
                    getAllIssuedDying(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filterFun("item_id")
                    )
                  );
                } else if (value == 3) {
                  dispatch(
                    getAllIssuedGrouping(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filterFun("item_id")
                    )
                  );
                } else if (value == 4) {
                  dispatch(
                    getAllRawMaterialHistory(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filterFun("")
                    )
                  );
                }
              }
            }}
            sx={{ width: 300, ml: "auto" }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
          {value == 0 && (
            <Div sx={{ display: "flex", columnGap: 1 }}>
              <Typography variant="h5" sx={{ pb: 2, mt: 1, ml: 2 }}>
                Page No:
              </Typography>
              <TextField
                sx={{ width: "100px" }}
                type="number"
                size="small"
                inputProps={{
                  min: 1,
                }}
                onKeyDown={(e) => {
                  const inputValue = parseInt(e.target.value + e.key, 10);
                  if (isNaN(inputValue) || inputValue < 0) {
                    e.preventDefault();
                  }
                }}
                value={pgNo}
                onChange={(e) => {
                  setLoaded(false);
                  setPgNo(e.target.value);
                }}
              ></TextField>
            </Div>
          )}
        </Box>
        <CustomTabPanel value={value} index={0}>
          {loaded ? (
            <RawMaterialTable
              palleteList={palleteList}
              filter={filterFun("")}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
              addGroup={addGroup}
              setAddGroup={setAddGroup}
              totalSqm={totalSqm}
              pgNo={pgNo}
              setPgNo={setPgNo}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {loaded ? (
            <IssuedSmokeTable
              filter={filterFun("item_id")}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {loaded ? (
            <IssuedDyingTable
              filter={filterFun("item_id")}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {loaded ? (
            <IssuedGroupTable
              filter={filterFun("item_id")}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          {loaded ? (
            <RawMaterialHistoryTable
              filter={filterFun("")}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default InventoryList;
